import React from "react"

const LessonIcon = ({ icon, filler }) => (
  <svg version="1.1" className={icon} x="0px" y="0px" viewBox="0 0 75 76">
    <path
      d="M19.5,68.4c-0.1,0-0.3-0.1-0.3-0.2c-0.3-0.5-0.4-1.3-0.2-1.7c0,0,0-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1
	c0.4,0.3,0.5,1.2,0.4,1.7C19.8,68.4,19.7,68.4,19.5,68.4z"
    />
    <ellipse className={filler} cx="37.3" cy="40.4" rx="32.7" ry="32.7" />
    <path
      d="M70,40.4c0-4.3-0.9-8.5-2.5-12.5c-1.6-3.9-4.1-7.6-7.1-10.6c-6.1-6-14.6-9.5-23.1-9.4c-4.2,0-8.5,0.9-12.4,2.6
	c-3.9,1.6-7.5,4.1-10.4,7.1c-6,6-9.4,14.4-9.4,22.8c0,4.2,0.8,8.4,2.4,12.3s4,7.5,7,10.5c3,3,6.5,5.4,10.4,7.1
	c3.9,1.7,8.1,2.5,12.4,2.6c8.5,0.1,17-3.3,23.1-9.4c3-3,5.4-6.6,7.1-10.6C69.1,49,70,44.7,70,40.4z M70,40.4c0,4.3-0.8,8.6-2.5,12.5
	s-4,7.6-7.1,10.6s-6.6,5.5-10.6,7.2s-8.2,2.6-12.6,2.6c-8.6,0.1-17.2-3.4-23.4-9.5c-3.1-3.1-5.5-6.7-7.2-10.7S4.1,44.8,4,40.4
	c0-4.3,0.9-8.7,2.6-12.7S10.7,20,13.8,17c6.1-6.1,14.8-9.6,23.4-9.5c4.3,0,8.6,0.9,12.6,2.6s7.6,4.1,10.6,7.2s5.4,6.7,7.1,10.6
	C69.2,31.9,70,36.2,70,40.4z"
    />
    <path
      className={filler}
      d="M59.7,24.8c1.5-1.5,2.9-3.2,4.2-4.9l0.6-0.8c0.1-0.1,0.1-0.2,0-0.2c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0.1
	L64,19.2c-1.6,1.7-3.3,3.5-4.7,5.4c0,0.1,0,0.1,0,0.2s0.2,0.2,0.3,0.2C59.6,24.9,59.7,24.9,59.7,24.8z M63.8,22.6
	c-0.4,0.5-0.8,0.9-1.2,1.4c-1.1,1.4-2.3,2.8-3.3,4.2l-0.1,0.2L59,28.3c-1.7-1.3-3.5-2.6-5.2-3.9l-1-0.8l0.2-0.2
	c0.5-0.4,0.9-0.9,1.4-1.4l0.2-0.3c0.1-0.1,0.3-0.3,0.4-0.5c0.5-0.5,1-1,1.2-1.6c0-0.1,0-0.2,0.1-0.3v-0.1l0.1-0.1
	c2.5-1.6,5.3-2.4,8-2.4c0.8,0,1.6,0.1,2.4,0.2h0.3L67,17.3c-0.3,0.9-0.7,1.7-1.1,2.5c-0.2,0.4-0.4,0.7-0.7,1.1
	c-0.2,0.4-0.5,0.8-0.7,1.3l-0.1,0.1h-0.1C64.1,22.4,63.9,22.5,63.8,22.6z"
    />
    <path
      d="M64,19.9c-1.3,1.7-2.7,3.4-4.2,4.9l-0.1,0.1c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.1,0-0.2c1.4-1.9,3.1-3.7,4.7-5.4l0.4-0.4
	l0.1-0.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0.1,0,0.2L64,19.9z"
    />
    <path
      d="M56,31.3c0.1,0.4,0.2,0.8,0.1,1.1c0,0.2-0.2,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.1c-0.2-0.2-0.2-0.6-0.2-1c0-0.1,0-0.2,0-0.4
	c0-0.1,0-0.3,0.2-0.4l0,0c0.2,0,0.3,0.1,0.3,0.2C55.9,31,56,31.2,56,31.3z"
    />
    <path
      d="M54.4,33.9c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1c-0.2-0.2-0.3-0.5-0.4-0.8
	c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0-0.3s0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.2,0.2
	C54.2,33.4,54.3,33.6,54.4,33.9z"
    />
    <path
      d="M52.2,34.7c0,0.1,0.1,0.2,0.1,0.3c0.2,0.3,0.3,0.7,0.3,1c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.1
	c-0.2-0.2-0.3-0.5-0.4-0.8c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c0.1-0.1,0.2-0.1,0.3-0.1C52.2,34.5,52.2,34.6,52.2,34.7z"
    />
    <path
      d="M50.9,36.8c0.1,0.2,0.2,0.5,0.2,0.7s-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1-0.1c-0.2-0.2-0.3-0.5-0.4-0.8c0-0.1,0-0.2-0.1-0.3
	c0-0.1,0-0.2,0-0.2c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0,0.3,0.2C50.9,36.6,50.9,36.7,50.9,36.8z"
    />
    <path
      d="M49.4,38.3c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.2-0.2-0.4-0.2-0.6v-0.2
	c0-0.1,0-0.2,0-0.2c0.1,0,0.1,0,0.2,0s0.2,0.1,0.2,0.2L49.4,38.3z"
    />
    <path
      d="M48,39.8c0.1,0.3,0.3,0.5,0.2,0.8c0,0.1-0.1,0.2-0.2,0.2h-0.1c-0.2-0.2-0.3-0.4-0.4-0.7v-0.2c0-0.1,0-0.1,0-0.2
	c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0.1C47.9,39.6,48,39.7,48,39.8z"
    />
    <path
      d="M46.5,41.5c0.1,0.2,0.2,0.5,0.1,0.7c0,0.2-0.2,0.3-0.3,0.3s-0.2,0-0.2-0.1c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.1,0-0.2,0-0.2
	s0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.2C46.5,41.3,46.5,41.4,46.5,41.5z"
    />
    <path
      d="M45,42.9c0.1,0.2,0.2,0.5,0.2,0.7c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1-0.1c-0.2-0.2-0.2-0.4-0.3-0.7v-0.2c0,0,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.1,0.1L45,42.9z"
    />
    <path
      d="M43.7,44.6c0.1,0.3,0.3,0.6,0.3,0.8v0.1c0,0-0.1,0.1-0.2,0.1h-0.1c-0.2-0.2-0.3-0.5-0.4-0.8c0-0.1-0.1-0.2-0.1-0.3v-0.1
	c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.1C43.6,44.4,43.7,44.5,43.7,44.6z"
    />
    <path
      d="M41.8,46.5c0-0.1-0.1-0.2-0.1-0.3s0-0.1,0-0.2c0,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3
	c0.1,0.3,0.3,0.6,0.3,0.8c0,0.1-0.1,0.2-0.2,0.2h-0.1C42,47.2,41.9,46.8,41.8,46.5z"
    />
    <path
      d="M40.9,48.2c0.1,0.2,0.2,0.5,0.2,0.7C41.1,49,41,49,41,49h-0.1c-0.1-0.1-0.2-0.3-0.3-0.5c0-0.1,0-0.1-0.1-0.2
	c0-0.1,0-0.2,0-0.2s0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0.1C40.8,48,40.9,48.1,40.9,48.2z"
    />
    <path
      d="M40,50.4C40,50.5,40,50.5,40,50.4L40,50.4c-0.2,0.1-0.3,0.1-0.3,0c-0.1-0.2-0.2-0.5-0.3-0.7c0-0.1-0.1-0.2-0.1-0.3
	s0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.2l0.1,0.2C39.9,49.9,40,50.2,40,50.4z"
    />
    <path
      d="M38.4,51c0.2,0.4,0.3,0.9,0.2,1.3c0,0,0,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1C38,52,37.9,51.4,37.9,51c0-0.1,0-0.1,0-0.2
	c0,0,0.1-0.1,0.2-0.1C38.2,50.8,38.3,50.9,38.4,51z"
    />
    <path
      d="M35.5,59.6c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.1,0-0.2,0-0.3s0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0.1,0.2,0.2l0.1,0.3
	c0.1,0.3,0.1,0.7,0.1,1c0,0.1-0.2,0.1-0.2,0.1C35.6,59.7,35.5,59.7,35.5,59.6z"
    />
    <path
      d="M34.4,59.8c0.1,0.3,0.2,0.5,0.2,0.8c0,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1c-0.2-0.2-0.2-0.4-0.3-0.7
	c0-0.1,0-0.2-0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.2V59.8z"
    />
    <path
      d="M32.8,62.4c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.5-0.3-0.8c0-0.1-0.1-0.2-0.1-0.3s0-0.2,0-0.2c0-0.1,0.2-0.1,0.2-0.1
	c0.1,0,0.2,0,0.3,0.2c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.2,0.6,0.2,0.9C33,62.4,32.9,62.4,32.8,62.4z"
    />
    <path
      d="M31.5,62.8c0.1,0.3,0.3,0.7,0.2,1c0,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1,0-0.2-0.1c-0.2-0.2-0.3-0.6-0.4-1v-0.2
	c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.2-0.1s0.2,0,0.3,0.2L31.5,62.8z"
    />
    <path d="M30.8,47.2c1.3-2,2.9-3.9,4.4-5.8C34.3,43.4,32.8,45.9,30.8,47.2z" />
    <path
      d="M34.6,44.8c-0.6,0.8-1.3,1.7-2.1,2.3c-0.5,0.4-1,0.8-1.5,1C30.9,48,30.7,48,30.5,48h-0.1v-0.2l0.1-0.2
	c0.1-0.2,0.2-0.3,0.3-0.5c2-1.3,3.4-3.7,4.4-5.8c0.7-0.8,1.3-1.6,2-2.4c0.2-0.2,0.4-0.5,0.7-0.8c-0.2,1.1-0.6,2.3-1.2,3.4
	c-0.3,0.5-0.5,1.1-0.9,1.7C35.5,43.8,35,44.3,34.6,44.8z"
    />
    <path d="M30.4,38.4c0,0,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1h0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1-0.1,0.1S30.5,38.4,30.4,38.4z" />
    <path d="M30.2,47.7c-0.1,0-0.1-0.1-0.2-0.1c0.3-0.1,0.6-0.3,0.8-0.5c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.2L30.2,47.7z" />
    <path
      d="M30.1,36C30,36,30,35.9,30,35.9s0-0.1,0.1-0.1h0.1c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1-0.1,0.1C30.2,36,30.2,36,30.1,36
	L30.1,36z"
    />
    <path
      d="M30,63.7c0,0.1,0.1,0.2,0.1,0.2c0.1,0.3,0.3,0.7,0.2,1c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0-0.1,0c-0.3-0.2-0.4-0.6-0.5-1
	c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2,0-0.2s0.1-0.1,0.2-0.1S29.9,63.5,30,63.7z"
    />
    <path
      d="M29.4,39.8c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2h0.1c0,0.1,0,0.2,0,0.2C29.6,39.8,29.6,39.9,29.4,39.8
	C29.5,39.9,29.5,39.9,29.4,39.8z"
    />
    <path d="M29.4,37.2c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0.2-0.1,0.2s-0.1-0.1-0.1-0.2C29.2,37.3,29.3,37.2,29.4,37.2z" />
    <path d="M29,38.1c0,0,0,0.1,0,0.2h-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1s0-0.2,0.1-0.2C29,38,29,38.1,29,38.1z" />
    <path d="M28.4,36c0.1,0,0.1,0.1,0.1,0.1s0,0.1-0.1,0.1s-0.1-0.1-0.1-0.1C28.3,36,28.3,36,28.4,36z" />
    <path
      d="M27.3,48.5c0.2-0.2,0.5-0.5,0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.2h0.1l0,0c0.1-0.2,0.4-0.3,0.6-0.3c0.1,0,0.2,0,0.3,0.1
	c0.2,0.1,0.5,0.2,0.7,0.4c-0.1,0-0.2,0.1-0.4,0.2c0,0-0.1,0-0.2,0.1l-0.7,0.2C28.2,48.2,27.8,48.4,27.3,48.5z"
    />
    <path
      d="M27.8,41.8L27.8,41.8c0-0.1,0-0.2,0.1-0.2H28l0.1,0.1c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.1-0.2,0.1
	C27.8,41.8,27.8,41.8,27.8,41.8z"
    />
    <path d="M27.5,38.8c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0.2-0.1,0.2S27.3,39,27.3,39C27.3,38.9,27.3,38.8,27.5,38.8z" />
    <path
      d="M27.3,48.5c0.5-0.1,1-0.3,1.4-0.4l0.7-0.2c0.1,0,0.1-0.1,0.2-0.1s0.2-0.1,0.4-0.2c0.1,0,0.1,0.1,0.2,0.1l0.2,0.1V48
	c-0.1,0-0.1,0-0.2,0c-0.7,0.2-1.3,0.6-1.8,1.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
	l-0.2,0.1l-0.1,0.1c-0.8,0.3-1.6,0.5-2.4,0.5c0.1-0.1,0.1-0.2,0.2-0.2C25.9,50,26.6,49.3,27.3,48.5z"
    />
    <path d="M27.4,40.5L27.4,40.5c0,0.1,0,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.2,0.2-0.2C27.3,40.5,27.3,40.5,27.4,40.5z" />
    <path d="M26.9,37.6c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0.2-0.1,0.2s-0.1-0.1-0.1-0.2C26.8,37.7,26.8,37.6,26.9,37.6z" />
    <path d="M26.6,36c0.1,0,0.1,0.1,0.1,0.2s0,0.2-0.1,0.2s-0.1-0.1-0.1-0.2C26.4,36.1,26.5,36,26.6,36z" />
    <path d="M26,40c0,0,0,0.2-0.1,0.2s-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0.1-0.2S26,39.9,26,40z" />
    <path d="M25.7,41.4c0,0.1,0,0.1-0.1,0.1s-0.1-0.1-0.1-0.1l0.1-0.1C25.6,41.3,25.7,41.4,25.7,41.4z" />
    <path d="M25.5,42.8c0.1,0,0.1,0.1,0.1,0.1s0,0.1-0.1,0.1s-0.1-0.1-0.1-0.1C25.4,42.8,25.5,42.8,25.5,42.8z" />
    <path
      d="M25.4,38c0,0.1-0.1,0.2-0.1,0.2h-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1c0.1,0,0.1-0.1,0.2-0.1l0,0
	C25.4,38,25.4,38,25.4,38z"
    />
    <path d="M24.5,40.5c0,0,0.1,0,0.1,0.1v0.1c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1v-0.1C24.4,40.5,24.5,40.5,24.5,40.5z" />
    <path d="M23.8,39.3c0,0,0,0.1-0.1,0.1s-0.1,0.1-0.2,0.1s-0.2,0-0.2-0.2s0.1-0.2,0.2-0.2h0.1C23.8,39.2,23.8,39.3,23.8,39.3z" />
    <path d="M23.5,42.3c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.2-0.2C23.3,42.5,23.4,42.3,23.5,42.3z" />
    <path d="M23.3,41.1c0,0,0,0.2-0.1,0.2s-0.1-0.1-0.1-0.2c0,0,0-0.2,0.1-0.2S23.3,41.1,23.3,41.1z" />
    <path d="M22.4,39.9L22.4,39.9C22.3,40,22.3,40,22.2,40S22,39.9,22,39.8c0-0.1,0.1-0.2,0.2-0.2h0.1C22.4,39.8,22.4,39.9,22.4,39.9z" />
    <path d="M21.6,42.8c0.1,0,0.1,0.1,0.1,0.1s0,0.1-0.1,0.1s-0.1-0.1-0.1-0.1C21.5,42.9,21.5,42.8,21.6,42.8z" />
    <path
      d="M21.5,41.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.1s0-0.1,0-0.2C21.3,41.1,21.4,41.1,21.5,41.1
	L21.5,41.1z"
    />
    <path d="M20.2,39.6c0,0.1,0,0.1-0.1,0.1S20,39.6,20,39.6l0.1-0.1L20.2,39.6z" />
    <path
      d="M62.6,24.1c0.4-0.5,0.8-1,1.2-1.4c0.2-0.2,0.3-0.3,0.5-0.3h0.1l0.1-0.1c0.2-0.4,0.5-0.9,0.7-1.3s0.5-0.7,0.7-1.1
	c0.4-0.8,0.8-1.6,1.1-2.5l0.1-0.3h-0.3c-0.8-0.1-1.6-0.2-2.4-0.2c-2.7,0-5.5,0.8-8,2.4l-0.1,0.1v0.1c0,0.1,0,0.2-0.1,0.3
	c-0.2,0.6-0.7,1.1-1.2,1.6c-0.2,0.2-0.3,0.3-0.4,0.5l-0.2,0.3c-0.4,0.5-0.8,1-1.4,1.4l-0.2,0.2l1,0.8c1.8,1.3,3.5,2.6,5.2,3.9
	l0.2,0.1l0.1-0.2C60.3,26.8,61.5,25.4,62.6,24.1z M31.8,48.8c2.3,1.7,4.5,3.8,6.9,6.6l0.2,0.2l0.2-0.3c5.6-9,13.2-17,20-24.2
	l0.2-0.2l-0.2-0.2C57,29,55,27.2,53,25.5l-1.2-1.1l-9.9,12c-0.9,1.1-1.8,2.3-2.8,3.4c-2.3,2.9-4.6,5.9-7.3,8.4l-0.2,0.2L31.8,48.8z
	 M27.6,50.3c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.2-0.3s0.2-0.3,0.3-0.4c0.5-0.7,1.1-1.1,1.8-1.2c0.1,0,0.1,0,0.2,0h0.1
	c0.2,0,0.4,0,0.5,0.1c0.5-0.3,1-0.6,1.5-1c0.8-0.7,1.5-1.5,2.1-2.3c0.4-0.5,0.8-1.1,1.2-1.5c0.3-0.6,0.6-1.2,0.9-1.7
	c0.5-1.1,0.9-2.3,1.2-3.4c0.7-0.8,1.4-1.7,2.1-2.5L50.5,23c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.3,0.1,0.5,0.2l0.2,0.2l0.1-0.2
	c0.3-0.6,0.7-1.2,1.2-1.8l0.2-0.2c0.1-0.1,0.2-0.3,0.4-0.5c0.5-0.6,0.9-1.2,1.5-1.6c0.1,0,0.1-0.1,0.2-0.1h0.1l0.1-0.1
	c2.5-2.2,5.6-3.4,9-3.4c1.2,0,2.5,0.1,3.7,0.5c0.1,0,0.2,0.1,0.3,0.1c0.4,0.2,0.7,0.5,0.5,1c-0.6,2.2-1.7,5.3-3.8,6.9h-0.1v0.1
	c0,0.1-0.1,0.2-0.2,0.4c-0.3,0.4-0.7,0.8-1,1.3c-1.1,1.4-2.2,2.8-3.4,4.1l-0.2,0.2l0.2,0.2c0.7,0.5,0.3,1,0.2,1.2l-0.1,0.1v0.1
	c0.1,0.2,0.1,0.5-0.2,0.8c-4.7,4.9-9,10.2-13.1,15.4c-2.5,3.1-5.1,6.3-7.7,9.4l-0.1,0.2l0.2,0.3c0.2,0.2,0.4,0.5,0.5,0.7
	c0.1,0.1,0.1,0.2,0.1,0.2v0.1h0.1c0.2,0.2,0.4,0.4,0.5,0.7c0,0.2,0,0.5-0.2,0.7c-3.2,3.3-6.7,6.8-11.2,11c-0.1,0.1-0.2,0.2-0.4,0.2
	h-0.1L29,71.2c-0.1,0.1-0.3,0.2-0.5,0.2s-0.3-0.1-0.4-0.2c-3.5-3.5-6.9-7.5-9.7-11.3l0,0l0,0c-0.1-0.1-0.2-0.2-0.3-0.4
	c0-0.1,0-0.2,0.1-0.4c2-2.6,4.3-5.1,6.6-7.5c0.9-0.1,1.7-0.3,2.4-0.5l0.1-0.1L27.6,50.3z M28.8,49.1C28,50,27.1,51,26.3,51.9
	c-2,2.3-4.1,4.7-6.3,6.8l-0.2,0.2L20,59c3,3,6.1,6.6,9,10.3l0.2,0.2l0.2-0.2c3.6-4,6.6-7.2,9.5-10.3l0.1-0.2l-0.1-0.2
	c-2.5-3.1-5.2-5.5-8-8.2c-0.6-0.5-1.1-1-1.7-1.6L29,48.9L28.8,49.1z"
    />
    <path d="M19.1,41.2c0.1,0,0.1,0.1,0.1,0.1s0,0.1-0.1,0.1S19,41.3,19,41.3S19,41.2,19.1,41.2z" />
    <path
      d="M16.7,41.5c1.7,2.2,4.3,3.5,7.1,3.5c1.9,0,3.7-0.6,5.2-1.7c3.7-2.8,4.5-8.1,1.8-11.9c-1.6-2.2-4-3.5-6.8-3.5
	c-2.2,0-4.3,0.8-6,2.2c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.1,0-0.2,0h-0.2L17,30.4C14.4,34.4,14.2,38.3,16.7,41.5z M24,46.3
	c-3.2,0-6.3-1.5-8.2-4.1c-1.6-2-2.3-4.9-1.9-7.6c0.4-2.5,1.6-4.5,3.4-5.8h0.1h0.1l0,0c1.9-1.4,4.1-2.2,6.4-2.2c3,0,5.8,1.3,7.7,3.7
	c3.4,4.2,2.8,10.4-1.3,13.8C28.5,45.5,26.3,46.3,24,46.3z"
    />
    <path
      d="M27.3,48.5c-0.7,0.8-1.4,1.5-2.1,2.2C25.1,50.8,25,50.9,25,51c-0.4,0-0.8,0.1-1.1,0.1c-4.5,0-9.3-2.5-11.9-6.2
	c-4-5.7-3.1-13.6,2-18.1v-0.1v-0.1c0-0.1,0-0.2,0.2-0.3c2.9-2.2,6.7-3.4,10.4-3.4c5.2,0,9.6,2.4,12,6.5c1.5,2.7,2,5.8,1.4,8.9
	c-0.2,0.2-0.4,0.5-0.7,0.8c-0.7,0.8-1.3,1.6-2,2.4c0.1-0.3,0.3-0.6,0.4-0.9c1.7-3.8,1.3-8.1-1-11.4c-2.3-3.2-5.6-4.9-9.7-4.9
	c-3,0-6.4,0.9-10,2.5l-0.2,0.1v0.2c0,0.1,0,0.3-0.1,0.4C10.2,32.3,9.6,39,13,44c1.8,2.6,4.5,4.4,7.7,5.1c0.8,0.2,1.5,0.2,2.3,0.2
	C24.4,49.2,25.8,48.9,27.3,48.5z"
    />
    <path
      d="M40.5,0.9c0.1,0.5,0.2,1.1,0.2,1.6s0,1.1,0.1,1.6c0,0.5,0,1.1-0.1,1.6c0,0.5-0.1,1.1-0.2,1.6c-0.1-0.5-0.2-1.1-0.2-1.6
	s-0.1-1.1-0.1-1.6s0-1.1,0.1-1.6C40.3,1.9,40.3,1.4,40.5,0.9z"
    />
    <path
      d="M43.4,4.2c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0.1-1,0.1-1.5,0.1s-1,0-1.5-0.1c-0.5,0-1-0.1-1.5-0.2C38,4.1,38.5,4,39,4
	s1-0.1,1.5-0.1s1,0,1.5,0.1C42.4,4,42.9,4.1,43.4,4.2z"
    />
    <path
      d="M16.3,6.4c0.1,0.4,0.2,0.8,0.2,1.2c0,0.4,0.1,0.8,0.1,1.2s0,0.8-0.1,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1-0.4-0.2-0.8-0.2-1.2
	C16,9.6,16,9.2,16,8.8s0-0.8,0.1-1.2C16.1,7.2,16.2,6.8,16.3,6.4z"
    />
    <path
      d="M18.5,8.9c-0.4,0.1-0.7,0.2-1.1,0.2s-0.7,0.1-1.1,0.1s-0.7,0-1.1-0.1c-0.4,0-0.7-0.1-1.1-0.2c0.4-0.1,0.7-0.2,1.1-0.2
	c0.4-0.1,0.7-0.1,1.1-0.1c0.4,0,0.7,0,1.1,0.1S18.1,8.8,18.5,8.9z"
    />
    <path
      d="M53.1,54.8c0.1,0.4,0.2,0.8,0.2,1.2s0.1,0.8,0.1,1.2s0,0.8-0.1,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1-0.4-0.2-0.8-0.2-1.2
	s-0.1-0.8-0.1-1.2s0-0.8,0.1-1.2C53,55.6,53,55.2,53.1,54.8z"
    />
    <path
      d="M55.3,57.2c-0.4,0.1-0.7,0.2-1.1,0.2s-0.7,0.1-1.1,0.1c-0.4,0-0.7,0-1.1-0.1c-0.4,0-0.7-0.1-1.1-0.2
	c0.4-0.1,0.7-0.2,1.1-0.2c0.4,0,0.7-0.1,1.1-0.1c0.4,0,0.7,0,1.1,0.1S55,57.1,55.3,57.2z"
    />
  </svg>
)

export default LessonIcon
