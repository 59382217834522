import React from "react"

const BridalIcon = ({ icon, filler }) => (
  <svg version="1.1" className={icon} x="0px" y="0px" viewBox="0 0 75 76">
    <ellipse className={filler} cx="37.3" cy="40.3" rx="32.7" ry="32.7" />
    <path
      d="M70.1,40.3c0-4.3-0.9-8.5-2.5-12.5c-1.7-4-4.1-7.6-7.1-10.6c-6.1-6.1-14.6-9.5-23.1-9.4c-4.2,0-8.5,0.9-12.4,2.6
	c-3.9,1.6-7.5,4.1-10.4,7.1c-3,3-5.4,6.6-7,10.5C6,31.8,5.1,36.1,5.1,40.3s0.8,8.4,2.4,12.3s4,7.5,7,10.5s6.5,5.4,10.4,7.1
	s8.1,2.5,12.4,2.6c8.5,0.1,17-3.3,23.1-9.4c3-3,5.5-6.6,7.1-10.6C69.2,48.9,70,44.6,70.1,40.3z M70.1,40.3c0,4.3-0.8,8.6-2.5,12.5
	c-1.6,4-4,7.6-7.1,10.6c-3,3-6.6,5.5-10.6,7.2s-8.3,2.6-12.6,2.6c-8.6,0.1-17.3-3.4-23.4-9.5c-3-3-5.5-6.7-7.2-10.7
	s-2.5-8.3-2.6-12.7c0-4.3,0.9-8.7,2.6-12.7s4.1-7.7,7.2-10.7c6.1-6.1,14.8-9.6,23.4-9.5c4.3,0,8.6,0.9,12.6,2.6s7.6,4.1,10.6,7.2
	c3,3,5.4,6.7,7.1,10.6S70.1,36,70.1,40.3z"
    />
    <path
      d="M42.8,33.6c-0.3-1.9-0.9-3.7-2-5.2c-0.3-0.4-0.6-0.7-0.9-1c-0.3-0.3-0.8-0.5-1.2-0.5c-0.9,0-1.9,0.1-2.8,0.2
	c-3.7,0.4-7.4,1.4-10.9,2.8c-1.7,0.7-3.4,1.5-5.1,2.4c-1.6,0.9-3.2,1.9-4.7,3s-2.9,2.3-4.3,3.6c-1.3,1.3-2.6,2.7-3.2,4.3
	c-0.6,1.5,0.1,3.4,1.1,4.9c0.5,0.8,1.1,1.5,1.7,2.2c0.6,0.6,1.3,1.2,2.1,1.5c1.6,0.8,3.5,0.9,5.3,0.8c1.8-0.1,3.7-0.5,5.5-1
	s3.6-1,5.4-1.7s3.4-1.5,5-2.5s3.1-2.1,4.5-3.4c1.4-1.3,2.7-2.7,3.8-4.2c-2.1,3.2-4.8,5.9-8,8c-1.6,1-3.3,1.9-5.1,2.7
	c-1.8,0.7-3.6,1.3-5.4,1.8s-3.7,1-5.6,1.1s-3.9,0-5.8-0.8c-0.9-0.4-1.8-1-2.5-1.7s-1.3-1.5-1.8-2.3C7.5,47.9,7,47,6.8,46
	c-0.3-1-0.3-2.1,0.1-3.1c0.7-1.9,2.1-3.4,3.5-4.7s2.9-2.5,4.4-3.7c3.1-2.2,6.5-4.1,10.1-5.4s7.3-2.2,11.1-2.5c1-0.1,1.9-0.1,2.9-0.1
	c0.5,0,1,0.3,1.4,0.6c0.3,0.4,0.6,0.7,0.9,1.1C42.1,29.8,42.7,31.7,42.8,33.6z"
    />
    <path
      d="M11.5,51.5c-1.4-3,0.1-7.1,3-8.8c2.5-2.1,5.2-4.1,8.1-5.8s6-3,9.2-3.8c1.6-0.4,3.3-0.6,5-0.7c0.8,0,1.7,0,2.5,0.1
	c0.8,0.2,1.7,0.5,2.2,1c-1.4-0.9-3.1-0.8-4.7-0.6c-1.6,0.1-3.2,0.5-4.8,1c-3.1,0.9-6.1,2.3-8.9,3.9c-2.8,1.6-5.5,3.5-8.2,5.4
	c-0.6,0.5-1.4,0.9-1.9,1.5s-1,1.3-1.3,2C11.2,48.2,11,49.9,11.5,51.5z"
    />
    <path
      d="M11.3,47.2c-0.2,0.6,0.3,1.1,0.8,1.2c0.5,0.1,1.1,0,1.6-0.1c1.1-0.2,2.2-0.3,3.3-0.6c2.2-0.5,4.3-1.1,6.4-1.9
	c2.1-0.8,4.1-1.7,6.1-2.8s3.9-2.3,5.7-3.6c-1.6,1.5-3.5,2.9-5.4,4.1c-1.9,1.2-4,2.2-6.1,3s-4.3,1.4-6.6,1.8
	c-1.1,0.2-2.2,0.3-3.4,0.4c-0.6,0-1.1,0.1-1.7-0.1C11.5,48.4,11.1,47.7,11.3,47.2z"
    />
    <path
      d="M30.1,42.7c-0.4-0.1-0.7-0.3-1.1-0.4l0,0l0,0c-0.1-1.1-0.1-2.2,0-3.4c0.1-1.1,0.4-2.3,1.1-3.2c0.3-0.5,0.8-0.9,1.3-1.2
	s1-0.6,1.6-0.8c1.1-0.4,2.2-0.6,3.3-0.8c4.5-0.6,9.1-0.4,13.5,0.5c2.2,0.5,4.4,1.1,6.5,1.8c2.1,0.8,4.2,1.8,6.2,2.9
	c0.5,0.3,1,0.6,1.5,0.9c0.5,0.3,1,0.6,1.4,0.9l0.7,0.5c0.2,0.2,0.5,0.3,0.7,0.6c0.5,0.5,0.8,1.2,0.8,1.8c0.2,2.4-0.8,4.7-2,6.6l0,0
	l0,0c-1,1.1-2.3,1.7-3.6,2.2s-2.6,0.8-4,0.9c-2.7,0.3-5.4,0.2-8.1-0.2c-2.7-0.4-5.3-0.9-7.9-1.7c-2.6-0.8-5.1-1.8-7.3-3.3
	c2.3,1.4,4.8,2.3,7.4,3c2.6,0.7,5.2,1.2,7.9,1.5c2.6,0.3,5.3,0.4,8,0c1.3-0.2,2.6-0.5,3.8-0.9c1.2-0.5,2.4-1.1,3.2-2l0,0
	c1.1-1.9,1.8-4,1.7-6.2c0-0.5-0.2-0.9-0.5-1.2c-0.1-0.1-0.4-0.3-0.6-0.5l-0.7-0.5c-0.4-0.3-0.9-0.6-1.4-0.9s-0.9-0.6-1.4-0.8
	c-1.9-1.1-4-2-6-2.8c-2.1-0.8-4.2-1.4-6.4-1.9c-4.3-0.9-8.8-1.1-13.2-0.8c-1.1,0.1-2.2,0.3-3.2,0.6c-0.5,0.2-1,0.4-1.5,0.7
	s-0.8,0.6-1.2,1c-0.6,0.8-1,1.9-1.2,3C29,40,29,41.1,29,42.2l0,0C29.4,42.3,29.7,42.6,30.1,42.7z"
    />
    <path
      d="M29,42.2c0.7-1.5,2.2-2.6,3.8-3c1.6-0.5,3.3-0.4,4.9-0.5c3.3-0.1,6.6,0.1,9.8,0.5c3.3,0.5,6.5,1.3,9.5,2.8
	c1.5,0.7,2.9,1.5,4.2,2.5c0.7,0.5,1.3,1,1.8,1.7c0.5,0.6,1,1.4,1.1,2.2c-0.5-1.6-1.9-2.6-3.3-3.4c-1.3-0.9-2.8-1.6-4.3-2.2
	c-3-1.2-6.1-2-9.3-2.5s-6.4-0.7-9.7-0.9c-1.6-0.1-3.2-0.2-4.8,0.1C31.3,39.9,29.8,40.8,29,42.2z"
    />
    <path
      d="M38.7,44c1.9,0.7,3.8,1.2,5.8,1.5c2,0.4,3.9,0.6,5.9,0.9c2,0.2,4,0.5,5.9,0.6c2,0.1,4-0.1,5.8-0.8c-1.7,1-3.8,1.4-5.8,1.4
	s-4-0.2-6-0.5s-4-0.5-5.9-1C42.3,45.6,40.4,45,38.7,44z"
    />
    <path d="M8,44.4c0.2-1.1,0.7-2.1,1.3-3s1.4-1.8,2.2-2.4c-0.8,0.8-1.5,1.6-2.1,2.5C8.8,42.4,8.4,43.4,8,44.4z" />
    <path d="M8.4,45.3c0.1-0.8,0.4-1.6,0.8-2.3c0.4-0.7,0.9-1.4,1.5-1.9c-0.5,0.7-1,1.3-1.3,2C9,43.8,8.7,44.5,8.4,45.3z" />
    <path d="M8.8,46.7c0.1-0.7,0.3-1.4,0.6-2.1c0.3-0.7,0.7-1.3,1.2-1.8c-0.4,0.6-0.8,1.2-1,1.9C9.2,45.4,9,46,8.8,46.7z" />
    <path d="M9.6,47.9c-0.1-0.7,0.1-1.3,0.3-2c0.2-0.6,0.6-1.2,1-1.7c-0.3,0.6-0.7,1.2-0.9,1.8C9.9,46.6,9.7,47.2,9.6,47.9z" />
    <path d="M10.1,48.9c-0.1-0.4,0-0.8,0.1-1.2s0.3-0.8,0.6-1c-0.1,0.4-0.3,0.7-0.4,1.1C10.3,48.1,10.2,48.5,10.1,48.9z" />
    <path d="M14.6,37.5c1.7-1.6,3.6-3,5.7-4.2c2.1-1.1,4.2-2.1,6.5-2.7c-2.2,0.8-4.4,1.7-6.4,2.9C18.3,34.6,16.4,36,14.6,37.5z" />
    <path d="M28.5,45c1.5-0.8,2.9-1.6,4.2-2.6c1.4-1,2.6-2.1,3.8-3.2c-1.1,1.3-2.3,2.4-3.7,3.4C31.5,43.5,30.1,44.4,28.5,45z" />
    <path d="M29.6,45.4c1.4-0.8,2.8-1.7,4.1-2.7s2.5-2.1,3.7-3.2c-1.1,1.2-2.3,2.3-3.6,3.3C32.5,43.8,31.1,44.7,29.6,45.4z" />
    <path d="M32.2,45.5c1.3-0.8,2.6-1.7,3.8-2.8c1.2-1,2.2-2.2,3.2-3.4c-0.9,1.3-2,2.5-3.1,3.6C34.9,43.9,33.6,44.8,32.2,45.5z" />
    <path d="M34.3,45.6c1.1-0.8,2.2-1.7,3.2-2.7c1-0.9,1.9-2,2.8-3c-0.8,1.2-1.7,2.2-2.7,3.2C36.5,44,35.5,44.9,34.3,45.6z" />
    <path d="M29.7,41.6c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.2,0.4,0.2,0.7,0.2c-0.2,0.1-0.5,0-0.8-0.1C29.9,42.1,29.7,41.9,29.7,41.6z" />
    <path d="M30.2,40.9c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.2,0.5,0.3,0.7,0.6c-0.3-0.1-0.5-0.3-0.8-0.4C30.6,41.3,30.4,41.1,30.2,40.9z" />
    <path d="M31,40.2c0.3,0.2,0.5,0.4,0.8,0.6c0.2,0.2,0.6,0.4,0.8,0.6c-0.3-0.1-0.6-0.3-0.9-0.4C31.5,40.8,31.2,40.5,31,40.2z" />
    <path d="M32.3,39.9c0.2,0.1,0.4,0.3,0.7,0.4c0.2,0.2,0.4,0.2,0.7,0.4c-0.3,0-0.5-0.1-0.8-0.2C32.6,40.3,32.4,40.1,32.3,39.9z" />
    <path d="M33.5,39.5c0.2,0.1,0.3,0.2,0.5,0.3s0.3,0.2,0.5,0.3c-0.2,0-0.4-0.1-0.6-0.1C33.7,39.8,33.5,39.7,33.5,39.5z" />
    <path d="M35.6,47c1.1,0.7,2.1,1.3,3.3,1.8c1.1,0.6,2.3,1,3.5,1.4C41.2,50,40,49.5,38.9,49C37.7,48.3,36.6,47.7,35.6,47z" />
    <path d="M36.5,46.1c0.9,0.6,1.9,1.3,2.9,1.7c1,0.5,2.1,0.9,3.2,1.3c-1.1-0.2-2.2-0.6-3.2-1.1C38.3,47.4,37.4,46.8,36.5,46.1z" />
    <path d="M37.4,45.7c0.6,0.3,1.2,0.7,1.8,0.9c0.6,0.3,1.2,0.6,1.8,0.9c-0.7-0.2-1.3-0.4-1.9-0.7C38.5,46.5,37.9,46.1,37.4,45.7z" />
    <path d="M38.2,44.8c0.4,0.3,0.8,0.6,1.3,0.8c0.4,0.3,0.9,0.4,1.4,0.6c-0.5-0.1-1-0.3-1.5-0.5C39,45.5,38.6,45.2,38.2,44.8z" />
    <path d="M53.7,48.4c1.4,0.1,2.9,0.1,4.3-0.2c1.4-0.2,2.8-0.8,4-1.4c-1.2,0.8-2.6,1.3-4,1.6C56.6,48.5,55.2,48.6,53.7,48.4z" />
    <path d="M55,49.5c1.4,0,2.8-0.1,4.2-0.5c1.4-0.3,2.7-0.9,3.9-1.6c-1.2,0.8-2.5,1.4-3.8,1.8C57.8,49.5,56.4,49.6,55,49.5z" />
    <path d="M55.6,50.6c1.4-0.1,2.9-0.2,4.3-0.7c1.4-0.4,2.7-1,4-1.7C62.7,49,61.4,49.6,60,50C58.5,50.5,57,50.7,55.6,50.6z" />
    <path d="M46.8,50.7c0.9,0.2,1.8,0.3,2.7,0.3c0.9,0.1,1.8,0,2.7-0.1c-0.9,0.2-1.8,0.3-2.7,0.2C48.6,51.1,47.7,51,46.8,50.7z" />
    <path d="M33.8,35.5c2.7-0.6,5.4-0.8,8.2-0.8c2.7,0.1,5.5,0.5,8.1,1.2c-2.7-0.6-5.4-1-8.1-1.1C39.2,34.8,36.5,35.1,33.8,35.5z" />
    <path
      d="M53.5,3.7c0.1,0.5,0.2,1.1,0.2,1.6s0.1,1.1,0.1,1.6s0,1.1-0.1,1.6c0,0.5-0.1,1.1-0.2,1.6c-0.1-0.5-0.2-1.1-0.2-1.6
	s-0.1-1.1-0.1-1.6s0-1.1,0.1-1.6C53.3,4.8,53.3,4.3,53.5,3.7z"
    />
    <path
      d="M56.4,7c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0.1-1,0.1-1.5,0.1s-1,0-1.5-0.1c-0.5,0-1-0.1-1.5-0.2c0.5-0.1,1-0.2,1.5-0.2
	c0.5-0.1,1-0.1,1.5-0.1s1,0,1.5,0.1S55.9,6.9,56.4,7z"
    />
    <path
      d="M48.3,2.3c0.1,0.4,0.2,0.8,0.2,1.2s0.1,0.8,0.1,1.2c0,0.4,0,0.8-0.1,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.1-0.4-0.2-0.8-0.2-1.2
	c0-0.4-0.1-0.8-0.1-1.2c0-0.4,0-0.8,0.1-1.2C48.2,3.1,48.2,2.7,48.3,2.3z"
    />
    <path
      d="M19.6,4.2c0.1,0.3,0.2,0.7,0.2,1s0.1,0.7,0.1,1s0,0.7-0.1,1c0,0.3-0.1,0.7-0.2,1c-0.1-0.4-0.2-0.7-0.2-1s-0.1-0.7-0.1-1
	s0-0.7,0.1-1C19.4,4.9,19.5,4.6,19.6,4.2z"
    />
    <path
      d="M21.5,6.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0.1-0.6,0.1-0.9,0.1s-0.6,0-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.2
	c0.3-0.1,0.6-0.2,0.9-0.2c0.3-0.1,0.6-0.1,0.9-0.1s0.6,0,0.9,0.1C20.8,6.2,21.1,6.3,21.5,6.4z"
    />
    <path
      d="M32.8,61.9c-0.1-0.5-0.2-1.1-0.3-1.6s-0.1-1.1-0.1-1.6s0-1.1,0-1.6s0.1-1.1,0.2-1.6c0.1,0.5,0.2,1.1,0.3,1.6
	s0.1,1.1,0.1,1.6s0,1.1,0,1.6C33,60.8,33,61.4,32.8,61.9z"
    />
    <path
      d="M29.9,58.5c0.5-0.1,1-0.2,1.5-0.2s1-0.1,1.5-0.1s1,0,1.5,0.1c0.5,0,1,0.1,1.5,0.2c-0.5,0.1-1,0.2-1.5,0.2s-1,0.1-1.5,0.1
	s-1,0-1.5-0.1C30.9,58.7,30.4,58.6,29.9,58.5z"
    />
    <path
      d="M38.2,67.2C38.1,66.8,38,66.4,38,66c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.4,0-0.8,0-1.2s0.1-0.8,0.2-1.2c0.1,0.4,0.2,0.8,0.2,1.2
	c0.1,0.4,0.1,0.8,0.1,1.2s0,0.8,0,1.2C38.3,66.3,38.3,66.8,38.2,67.2z"
    />
    <path
      d="M35.8,64.8c0.4-0.1,0.7-0.2,1.1-0.2c0.4-0.1,0.7-0.1,1.1-0.1c0.4,0,0.7,0,1.1,0.1c0.4,0,0.7,0.1,1.1,0.2
	c-0.4,0.1-0.7,0.2-1.1,0.2c-0.4,0-0.8,0-1.1,0c-0.4,0-0.7,0-1.1-0.1S36.2,64.9,35.8,64.8z"
    />
  </svg>
)

export default BridalIcon
